<script setup lang="ts">
import {CSpinner, CTooltip} from "@coreui/vue";

const checkpointsSummaryStore = useCheckpointsSummaryStore();
const { openCheckpointsSummary, checkpointsSummaryPending } = storeToRefs(checkpointsSummaryStore);

const { t } = useI18n();

await useLazyAsyncData(() => checkpointsSummaryStore.fetch());

useSeoMeta({
  title: t('home.page_title'),
  ogTitle: t('home.page_title'),
});
</script>

<template>
  <div class="row g-4">
    <div
      v-if="checkpointsSummaryPending"
      class="text-center"
    >
      <CSpinner />
    </div>
    <div
      v-for="summary in openCheckpointsSummary"
      v-else-if="openCheckpointsSummary.length > 0"
      :key="summary.code"
      class="col-12 col-md-6 col-lg-4 col-xl-3"
    >
      <div class="card">
        <NuxtLink :to="{ name: 'checkpoint-code', params: { code: summary.code } }">
          <img
            class="card-img-top"
            :src="'/images/checkpoints/' + summary.code + '.jpg'"
            :alt="summary.name"
          >
        </NuxtLink>
        <div class="card-body position-relative bg-light">
          <div class="position-absolute top-0 end-0 pt-2 pe-3">
            <i class="cil-arrow-thick-to-right icon icon-md align-top mt-2" />
            <i
              class="icon icon-xxl"
              :class="'cif-' + summary.countryCode"
            />
          </div>
          <h5 class="card-title">
            <NuxtLink
              class="h5 link-underline link-underline-opacity-0"
              :to="{ name: 'checkpoint-code', params: { code: summary.code } }"
            >
              {{ summary.name }}
            </NuxtLink>
          </h5>
          <CTooltip
            v-if="summary.waitingZone"
            :content="$t('home.tooltip_waiting_zone')"
          >
            <template #toggler="{ on }">
              <div
                class="d-inline-block me-1"
                v-on="on"
              >
                <i class="cil-parking icon icon-lg" />
                <span class="h5 ms-1">{{ summary.waitingZoneNumbers?.totalNumber }}</span>
              </div>
            </template>
          </CTooltip>
          <CTooltip
            v-if="summary.total"
            :content="$t('home.tooltip_total_number', {date: summary.total.date.toFormat('H')})"
          >
            <template #toggler="{ on }">
              <div
                class="d-inline-block"
                v-on="on"
              >
                <i class="cil-car icon icon-lg" />
                <span class="h5 ms-1">{{ summary.total?.number }}</span>
              </div>
            </template>
          </CTooltip>
          <CTooltip
            v-if="summary.waitingZone && null !== summary.waitingZoneDuration"
            :content="$t('home.tooltip_waiting_zone_duration')"
          >
            <template #toggler="{ on }">
              <div
                class="d-inline-block float-end"
                v-on="on"
              >
                <i class="cil-hourglass icon icon-lg" />
                <FormattedWaitingZoneDuration
                  class="ms-1"
                  :duration="summary.waitingZoneDuration"
                />
              </div>
            </template>
          </CTooltip>
        </div>
      </div>
    </div>
    <div
      v-else
      class="text-center"
    >
      <h3>{{ $t('error.no_data_loaded') }}</h3>
      <button
        class="btn btn-primary"
        @click="checkpointsSummaryStore.fetch()"
      >
        {{ $t('error.try_again') }}
      </button>
    </div>
  </div>
</template>
